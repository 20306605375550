export class IsmsFormInfo {
   IsmsFormInfoID: string;
   Name: string;
}

export class IsmsFormIncidentReportNonIT {
   Name: string;
   Telephone: string;
   Email: string;
   Department: string;
   IncidentDate: string;
   SeverityLevel: number;
   NatureOfTheIncidentOrProblem: number;
   AdditionalInformation: string;
   NotificationOfPolice: number;
   OthersContactedHRAD: boolean;
   OthersContactedISMC: boolean;
   OthersContactedMODI: boolean;
   OthersContactedOther: boolean;
   OthersContactedOtherDetail: string;
   PriorOccurrence: number;
   PriorOccurrenceDetail: string;
   OffenderIdentified: number;
   DataCompromised: number;
   DataCompromisedDetail: string;
   BusinessImpact: number;
   BusinessImpactDetail: string;
   RemedialAction: number;
   RemedialActionDetail: string;
   OfficeUse_PeopleInvolved_IncidentResponse_Checked: boolean;
   OfficeUse_PeopleInvolved_IncidentResponse: string;
   OfficeUse_PeopleInvolved_IncidentResponse_Signature: any;
   OfficeUse_PeopleInvolved_Other_Checked: boolean;
   OfficeUse_PeopleInvolved_Other: string;
   OfficeUse_PeopleInvolved_Other_Signature: any;
   OfficeUse_NeedInvestigation: number;
   OfficeUse_CostOfIncident: number;
   OfficeUse_CISO_Signature: any;
   OfficeUse_Date: string;
   CreatedBy: string;
   CreatedDateTime: Date;
   UpdatedDateTime: Date;
   LastUpdatedDateTime: Date;
}