import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';
import { ProductInfo } from '../model/product';
import { SalesInfo } from '../model/sales';
import { CommonFunction } from '../common/common_function';

@Injectable({
  providedIn: 'root'
})

export class SalesService {

  // Base url
  baseurl = CommonFunction.ServerURL + '/api/pos';

  constructor(private http: HttpClient,
    private authService: AuthService) {
  }

  // GET
  GetSales(): Observable<SalesInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany(),
      })
    }

    return this.http.get<SalesInfo[]>(this.baseurl + '/GetSales?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // // GET
  // GetAllDepartment(): Observable<DepartmentInfo[]> {
  //   // Http Headers
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Token ' + this.authService.getToken(),
  //       'Company': this.authService.getCompany(),
  //     })
  //   }

  //   // ////console.log('headers: ' + JSON.stringify(httpOptions));

  //   return this.http.get<DepartmentInfo[]>(this.baseurl + '/GetAllDepartment?companyID=' + this.authService.getCompany(), httpOptions)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  // }

  // // GET
  // GetAllPosition(): Observable<PositionInfo[]> {
  //   // Http Headers
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Token ' + this.authService.getToken(),
  //       'Company': this.authService.getCompany(),
  //     })
  //   }

  //   return this.http.get<PositionInfo[]>(this.baseurl + '/GetAllPosition?companyID=' + this.authService.getCompany(), httpOptions)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  // }

  // // GET
  // GetAllLevel(): Observable<LevelInfo[]> {
  //   // Http Headers
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Token ' + this.authService.getToken(),
  //       'Company': this.authService.getCompany(),
  //     })
  //   }

  //   // ////console.log('headers: ' + JSON.stringify(httpOptions));

  //   return this.http.get<LevelInfo[]>(this.baseurl + '/GetAllLevel?companyID=' + this.authService.getCompany(), httpOptions)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  // }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      ////console.log('Sales service error: ' + errorMessage);

      // Show connection error
      if(error.status == 0) {
        errorMessage = 'Failed to connect to server';
      } 
      // Do not show full detail of error, either show Internal Error from API or Error Code only
      else if(error.error) {
        errorMessage = error.error;
      } else {
        errorMessage = `Error Code: ${error.status}`;
      }
    }
    return throwError(errorMessage);
  }
}